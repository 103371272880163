<template>
  <div>
    <el-card>
      <el-table :data="list">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="cate.title" label="所属分类" show-overflow-tooltip></el-table-column>
        <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
        <el-table-column label="缩略图" >
          <template v-slot="s">
            <img :src="s.row.pic | tomedia" style="height: 3rem;width: 3rem;border-radius: 3px" alt="">
          </template>
        </el-table-column>
        <el-table-column label="页数">
          <template v-slot="s">
            <el-button @click="toAlbumImg(s.row)">{{s.row.page_total}}</el-button>
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot:header>
            <el-button type="primary" @click="toAlbumEdit">添加画册</el-button>
          </template>
          <template v-slot="s">
            <el-button @click="toAlbumImg(s.row)">图片管理</el-button>
            <el-button type="primary" @click="toAlbumEdit(s.row)">编辑</el-button>
            <el-button type="danger" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="size" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
/**
 * 画册列表
 * 添加、修改入口
 * 操作删除画册
 */
export default {
  name: "list",
  data(){
    return{
      list:[],
      page:1,
      size:0,
      total:0,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    toAlbumEdit(item=false){
      let outQuery = item ? {id:item.id} : {}
      this.$router.push({
        name:"album-manage-edit",
        query:outQuery
      })
    },
    toAlbumImg({id}){
      this.$router.push({
        name:"album-manage-img-list",
        query:{
          album_id:id,
        }
      })
    },
    load(){
      this.$u.api.album.search({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    del({id}){
      this.$u.api.album.del({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>

</style>